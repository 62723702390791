<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryFormRef"
      size="small"
      :inline="true"
      label-width="88px"
    >
      <el-form-item label="会员名称" prop="memberName">
        <el-input
          v-model="queryParams.memberName"
          placeholder="请输入会员名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="会员昵称" prop="nickName">
        <el-input
          v-model="queryParams.nickName"
          placeholder="请输入会员昵称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="会员手机号" prop="userPhone">
        <el-input
          v-model="queryParams.userPhone"
          placeholder="请输入会员手机号"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="城市" prop="city">
        <el-input
          v-model="queryParams.city"
          placeholder="请输入城市"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="门店名称" prop="shopName">
        <el-input
          v-model="queryParams.shopName"
          placeholder="请输入门店名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="咨询类型" prop="consultingType">
        <el-select
          v-model="queryParams.consultingType"
          placeholder="请选择咨询类型"
          clearable
        >
          <el-option
            v-for="t in typeOptions"
            :key="t.id"
            :value="t.value"
            :label="t.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search" type="primary"
          >搜索</el-button
        >
        <el-button @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        <el-button
          @click="exportExcel()"
          icon="el-icon-download"
          type="primary"
          :loading="exportLoading"
          >导出</el-button
        >
        <el-button @click="openConsultationTypeList()" type="primary"
          >咨询类型管理</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      stripe
      style="width: 100%"
      highlight-current-row
      v-loading="loading"
      row-class-name="row-class"
    >
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      />
      <el-table-column prop="id" label="ID" width="330">
        <template #default="scope">
          <el-tooltip :content="scope.row.id" placement="top">
            <p class="row-overflow">{{ scope.row.id }}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="memberName" label="会员名称" show-overflow-tooltip width="160">
      </el-table-column>
      <el-table-column
        prop="nickName"
        label="会员昵称"
        width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="userPhone" label="会员手机号" width="200">
      </el-table-column>
      <el-table-column
        prop="shopName"
        label="门店"
        width="170"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="consultingType" label="咨询类型" width="110">
        <template #default="scope">
          {{
            typeOptions.find((t) => t.value == scope.row.consultingType)?.key ||
            scope.row.consultingType
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="lastConsultingContent"
        label="最后一次咨询内容"
        width="350"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="lastConsultingTime"
        label="最后一次咨询时间"
        width="170"
      >
        <template #default="scope">
          <el-tooltip
            :content="formatTime(scope.row.lastConsultingTime)"
            placement="top"
          >
            <p class="row-overflow">
              {{ formatTime(scope.row.lastConsultingTime) }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="largeArea" label="分公司" width="250">
      </el-table-column>
      <!-- <el-table-column prop="smallArea" label="小区" width="160">
      </el-table-column> -->
      <el-table-column prop="city" label="城市" width="100"> </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" @click="showDetail(scope.row)" type="text"
            >全部对话</el-button
          >
          <el-button size="small" @click="showMemberInfo(scope.row)" type="text"
            >客户资料</el-button
          >
          <el-button
            size="small"
            @click="handleSetConsultationType(scope.row)"
            type="text"
            >设置类型</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="queryParams.page"
      :page-size="queryParams.pageSize"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 表单弹窗：会话详情 -->
    <CustomerConsultationForm ref="formRef" @success="getList" />
    <!-- 设置咨询类型 -->
    <ConsultationSetTypeForm ref="typeFormRef" @success="getList" />
  </div>
</template>

<!-- 本页的js -->
<script>
import { onMounted, reactive, ref } from "vue";
import {
  getConsultationList,
  getConsultationTypeList,
  exportConsultation,
} from "../../http/consultation";
import { FullTimeFormat } from "../../utils/common";
import CustomerConsultationForm from "./CustomerConsultationForm.vue";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { ElMessage } from "element-plus";
import ConsultationSetTypeForm from "./ConsultationSetTypeForm.vue";
import { useRouter } from "vue-router";

export default {
  name: "Consultation", // 咨询管理
  components: { CustomerConsultationForm, ConsultationSetTypeForm },
  setup() {
    const loading = ref(true);
    const list = ref([]);
    const total = ref(0);
    //声明数据
    let queryParams = reactive({
      memberName: "",
      nickName: "",
      userPhone: "",
      city: "",
      shopName: "",
      consultingType: "",
      page: 1,
      pageSize: 10,
    });
    const queryFormRef = ref();

    let resetQuery = () => {
      queryFormRef.value.resetFields();
      handleQuery();
    };

    const typeOptions = ref([]);

    const router = useRouter();

    // 查看详情
    const formRef = ref();
    let showDetail = async (row) => {
      formRef.value.open(row);
    };

    // 设置咨询类型
    const typeFormRef = ref();
    const handleSetConsultationType = (row) => {
      typeFormRef.value.open(row);
    };

    // 查看客户资料
    let showMemberInfo = (row) => {
      router.push({
        name: "MemberDetail",
        query: { memberId: row.memberId || row.memberID },
      });
    };

    let indexMethod = (index) => {
      return index + (queryParams.page - 1) * queryParams.pageSize + 1;
    };

    // 获取列表
    const getList = async () => {
      loading.value = true;
      try {
        const data = await getConsultationList(queryParams);
        list.value = data.items;
        total.value = data.totalCount;
      } finally {
        loading.value = false;
      }
    };

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      queryParams.page = index;
      getList();
    };

    //导出表格为excel
    let exportExcel = () => {
      //开始导出
      exportData();
    };

    const exportLoading = ref(false);

    let exportData = async () => {
      exportLoading.value = true;
      try {
        let res = await exportConsultation(queryParams);
        let reg = /\\/g;
        let replaceAfter = res.eValues.replace(reg, "");
        var jsondata = JSON.parse(replaceAfter);
        let time = new Date().getTime();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(jsondata);
        XLSX.utils.book_append_sheet(workbook, worksheet, "data");
        XLSX.writeFile(workbook, "客户咨询表_" + time + ".xlsx");
      } finally {
        exportLoading.value = false;
      }
    };

    let handleQuery = () => {
      getList();
    };

    // 获取咨询类型选项
    const fetchConsultationTypeList = async () => {
      try {
        let res = await getConsultationTypeList();
        typeOptions.value = res ?? [];
      } catch (e) {
        console.log("===获取咨询类型选项：", e);
      }
    };

    const formatTime = (date) => {
      return FullTimeFormat(date);
    };

    // 打开咨询类型管理
    const openConsultationTypeList = () => {
      router.push({ name: "ConsultationType" });
    };

    // 初始化
    onMounted(() => {
      getList();
      fetchConsultationTypeList();
    });

    return {
      loading,
      exportLoading,
      queryParams,
      queryFormRef,
      list,
      total,
      typeOptions,
      getList,
      handleCurrentChange,
      indexMethod,
      exportExcel,
      exportData,
      handleQuery,
      resetQuery,
      fetchConsultationTypeList,
      formatTime,
      showDetail,
      showMemberInfo,
      formRef,
      typeFormRef,
      handleSetConsultationType,
      openConsultationTypeList,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.row-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行数
  -webkit-box-orient: vertical;
}
.fenye {
  margin-top: 20px;
}
</style>
